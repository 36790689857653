import React, {useEffect, useState} from 'react';
import {getQueryParam} from "../infrastructure/getQueryParam";
import {useLocation} from "@reach/router";
import {ThemeProvider} from "styled-components";
import {ResetCSS} from "../common/assets/css/style";
import Seo from "../components/seo";
import Navbar from 'containers/AppMinimal/Navbar';
import {theme} from 'common/theme/appminimal';
import {useCookies} from "react-cookie";
import {navigate} from "gatsby-link";
import {handleFindOrder} from "../usecases/order/findOrder";
import Sticky from "react-stickynode";
import Footer from "../containers/AppMinimal/Footer";
import {DrawerProvider} from 'common/contexts/DrawerContext';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEnvelope, faPlane} from "@fortawesome/free-solid-svg-icons";
import Container from "../common/components/UI/Container";

const ThankYouPageOld = () => {

    const location = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies(['order']);
    const [order, setOrder] = useState(null);

    const loadOrder = async (ref) => {
        try {
            const res = await handleFindOrder(ref);
            if (typeof window !== 'undefined' && window.dataLayer) {
                console.log("send push");
                window.dataLayer.push({
                    'event': 'purchase',
                    'transaction_id': res.data.id,
                    'value': res.data.totalAmount,
                    'currency': 'USD'
                });
            }
            setOrder(res.data);
        } catch (error) {
            navigate("/");
        }
    }

    useEffect(() => {
        removeCookie('order');
        const orderRef = getQueryParam("ref", location);
        if (orderRef === "") {
            navigate("/");
        }
        loadOrder(orderRef);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Thank You for Your Purchase"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <div id="checkout">
                        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active noHarp">
                            <DrawerProvider>
                                <Navbar/>
                            </DrawerProvider>
                        </Sticky>
                    </div>
                    <ContentWrapper>
                        <Container className="Container tContainer">
                            {order && (
                                <section style={{paddingTop: "50px"}}>
                                    <div>
                                        <p style={{
                                            color: "gray",
                                            marginBottom: "0px",
                                            paddingBottom: "0px"
                                        }}>Confirmation: {order.reference}</p>
                                        <h1 style={{fontWeight: "normal", marginTop: "10px"}}>
                                            <FontAwesomeIcon icon={faCheck}
                                                             style={{
                                                                 fontSize: "30px",
                                                                 color: "green",
                                                                 marginRight: "10px"
                                                             }}
                                            />

                                            Thank you for your purchase!
                                        </h1>
                                    </div>
                                    <div className="tbox pp" style={{textAlign: 'center'}}>
                                        <img src="/img/baggage.png" width="200px"
                                             style={{marginBottom: "20px", marginTop: "20px"}}/>
                                        <p><b>Your order is confirmed</b></p>
                                        <p>You'll receive an email with your tickets shortly.</p>
                                        <p>The email may take between 3 and 4 hours to arrive.</p>

                                    </div>
                                    <div className="tbox">
                                        <h3>Purchase Details:</h3>
                                        <p>{order.fromAirport} - {order.toAirport}</p>
                                        <p>Total Tickets: {order.totalTickets}</p>
                                        <p>Total Amount: {order.totalAmount} {order.currency}</p>

                                    </div>
                                    <div className="tbox" style={{ marginBottom: "50px"}}>
                                        <h3>Contact Information:</h3>
                                        <p>info@onwardticketpro.com</p>
                                    </div>
                                </section>
                            )}
                        </Container>
                    </ContentWrapper>
                    <Footer/>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default ThankYouPageOld;
